<template>
  <nav class="topbar">
    <div class="brand">
      <router-link to="/audit" class="brand-icon">
        Audit Energetique
      </router-link>
    </div>
    <div class="right">
      <div class="settings">
        <b-dropdown id="dropdown-right" right class="m-md-2" no-caret>
          <template #button-content>
            <img src="@/assets/Icon feather-settings.png" />
          </template>
          <b-dropdown-item active class="main-color"> GENERAL </b-dropdown-item>
          <li role="presentation">
            <router-link to="/audit" class="dropdown-item" role="menuitem"
              >Audit Energetique</router-link
            >
          </li>
          <li role="presentation" v-if="isSuperAdmin">
            <router-link to="/user" class="dropdown-item" role="menuitem"
              >Gestion Utilisateur</router-link
            >
          </li>
          <b-dropdown-divider></b-dropdown-divider>
          <li role="presentation">
            <a @click="loggingout()" class="dropdown-item" role="menuitem">
              <strong class="red">Deconnexion</strong>
            </a>
          </li>
        </b-dropdown>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'batigoNavBar',
  methods: {
    ...mapActions(['logout']),
    loggingout() {
      this.logout();
    },
  },

  computed: {
    ...mapGetters(['getOnlineUser']),
    isSuperAdmin() {
      return this.getOnlineUser == 'admin';
    },
  },
};
</script>

<style lang="scss">
* {
  font-family: Montserrat;
  margin: 0;
  padding: 0;
}

.topbar .right .lang .btn-secondary {
  border-radius: 50% !important;
  padding: 6px 10px;
  text-transform: uppercase;
}

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 16px;
  box-shadow: 0px 2px 2px 0px #00000019;
  background-color: #fff;

  .brand .brand-icon {
    font-size: 20px;
    color: #28367a;
    font-weight: 600;
    text-decoration: none;
  }

  .right {
    @media only screen and (max-width: 900px) {
      order: 3;
    }
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .info {
      @media only screen and (max-width: 900px) {
        display: none;
      }
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-right: 8px;

      h1,
      h2 {
        margin: 0;
        text-align: right;
      }
      h1 {
        font-size: 18px;
        font-weight: 500;
        color: #545454;
      }

      h2 {
        font-size: 14px;
        color: #aaaaaa;
      }
    }

    .profil-img {
      @media only screen and (max-width: 900px) {
        display: none;
      }
      width: 100%;
      img {
        width: 85%;
      }
    }
    .settings {
      position: relative;

      .icon {
        font-size: 28px;
      }

      .dropdown-item.active,
      .dropdown-item:active {
        background-color: #e9e9e9;
        color: #28367a;
        font-weight: 600;
      }
    }

    .btn-secondary {
      background-color: #e4261b !important;
      border: none !important;
      box-shadow: none !important;
      padding: 0.32rem 0.4rem;
      border-radius: 50% !important;

      .icon {
        color: #545454;
      }
    }
  }

  .toggle-button {
    @media only screen and (max-width: 900px) {
      display: block;
      order: 1;
    }
    display: none;

    .icon {
      font-size: 20px;
      right: 0;
    }

    .btn {
      background-color: #28367a;
      display: flex;
    }

    #sidebar-backdrop {
      width: 230px;
    }

    .b-sidebar-header {
      padding: 12px;
      justify-content: center;
      border-bottom: 2px solid #e9ecef;
      position: relative;

      #hide {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0;
        background-color: transparent;
        border: none;
      }

      .user {
        text-align: center;
        & img {
          display: inline-block;
          width: 80px;
        }
        .info {
          display: flex;
          flex-direction: column;
          padding: 8px 0 0;

          h1 {
            font-size: 18px;
            font-weight: 500;
            color: #545454;
          }

          h2 {
            font-size: 14px;
            color: #aaaaaa;
          }
        }
      }
    }

    .b-sidebar-body {
      overflow-y: scroll;

      & .active {
        margin: 0;
        padding-left: 24px;
        background-color: #e4261b;
        color: #fff;
        transition: all 0.2s ease;

        &:hover {
          background-color: rgba(#28367a, 0.8);
        }
      }
    }

    .nav-link {
      padding: 16px 8px;
      margin-left: 16px;
      border-bottom: 2px solid #e9ecef;
      transition: all 0.2s ease;
      &:hover {
        background-color: #28367a;
        color: #fff;
      }
    }
  }
}
@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.css');
body {
  font-family: 'Didact Gothic', sans-serif;
  color: #333;
  box-sizing: border-box;
}

.red {
  color: red;
  cursor: pointer;
}
</style>
